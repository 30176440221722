*, *:before, *:after {
  box-sizing: border-box;
}

/* ---------- */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: normal;
}

/* ---------- */

.recharts-responsive-container {
  position: relative;
}

.recharts-responsive-container>.recharts-wrapper, .recharts-responsive-container>.recharts-wrapper>svg {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
}

.recharts-responsive-container ul.recharts-default-legend>li .recharts-legend-item-text {
  vertical-align: middle;
  font-weight: 400;
}

.recharts-responsive-container ul.recharts-default-legend>li svg {
  vertical-align: middle;
}

/* ---------- */

.ResultsDashboard h1, .ReportDashboard h1 {
  font-family: 'Open Sans', sans-serif;
  color: #2a3847;
  margin: 0 0 40px;
  padding: 0;
  line-height: normal;
  font-weight: 600;
  font-size: 36px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.ResultsDashboard h2, .ReportDashboard h2 {
  font-family: 'Open Sans', sans-serif;
  color: #2a3847;
  margin: 0 0 20px;
  padding: 0;
  line-height: normal;
  font-weight: 600;
  font-size: 25px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.ReportDashboard p {
  font-family: 'Open Sans', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 10px;
  padding: 0;
  line-height: normal;
  font-weight: 400;
  font-size: 14px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.ResultsDashboard p:last-child, .ReportDashboard p:last-child {
  margin: 0;
}

.ReportDashboard .dashboard_sections {
  margin: 0 0 40px;
}

.ReportDashboard .dashboard_sections:last-child {
  margin: 0;
}

/* ---------- */

.CustomActiveShapePieChart {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin: 0 -15px;
}

.CustomActiveShapePieChart>div:nth-child(1) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  padding: 0 15px;
  width: 40%;
  max-width: 40%;
  text-align: center;
}

.CustomActiveShapePieChart>div:nth-child(2) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  padding: 0 15px;
  width: 60%;
  max-width: 60%;
}

.CustomActiveShapePieChart p {
  display: block;
  font-size: 14px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
}

.CustomActiveShapePieChart p>span, .CustomActiveShapePieChart p>strong {
  vertical-align: middle;
}

.CustomActiveShapePieChart p .customLegend {
  display: inline-block;
  width: 14px;
  height: 10.5px;
  overflow: hidden;
  margin: 0 5px 0 0;
  padding: 0;
  font: 400 0/0 sans-serif;
  text-indent: 100%;
}

.CustomActiveShapePieChart .lenend {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  width: auto;
  text-align: left;
}

@media (max-width: 991px) {
  .CustomActiveShapePieChart>div:nth-child(1) {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .CustomActiveShapePieChart>div:nth-child(2) {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    max-width: 100%;
  }
  .CustomActiveShapePieChart p {
    display: inline-block;
    margin: 0 10px 0 0;
    width: auto;
    max-width: calc(100% - 10px);
    vertical-align: top;
  }
  .CustomActiveShapePieChart .lenend {
    text-align: center;
  }
}

/* ---------- */

.layout {
  width: 100% !important;
  min-width: 100% !important;
}

.layout main {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.layout main>div:nth-child(1) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.layout main>div:nth-child(1):last-child {
  width: 100%;
  max-width: 100%;
}

.layout main>div:nth-child(2) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.layout main>div:nth-child(2) .ResultsDashboard {
  width: 100%;
  overflow: hidden;
  max-width: 100%;
}

.mainLayout .layout main>div:nth-child(1) {
  width: 55px;
  max-width: 55px;
  transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.mainLayout .layout main>div:nth-child(2) {
  width: calc(100% - 55px);
  max-width: calc(100% - 55px);
  transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.mainLayout.open .layout main>div:nth-child(1) {
  width: 240px;
  max-width: 240px;
}

.mainLayout.open .layout main>div:nth-child(2) {
  width: calc(100% - 240px);
  max-width: calc(100% - 240px);
}

.mainLayout .layout main>div:nth-child(1):last-child {
  width: 100% !important;
  max-width: 100% !important;
}

/* ---------- */

.flex_link_col {}

.flex_link_col {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: auto;
  margin: 0 -5px;
  position: relative;
}

.flex_link_col>div:nth-child(1) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: left;
  width: 50%;
  max-width: 50%;
  padding: 0 5px;
}

.flex_link_col>div:nth-child(2) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: right;
  width: 50%;
  max-width: 50%;
  padding: 0 5px;
}

/* ---------- */